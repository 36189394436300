.spinner-wrapper{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 9999;
    background: #fff;
    visibility: visible;
    opacity: 1;  
    transition: visibility 0.5s, opacity 0.3s linear;
    &.hide{
        visibility: hidden;
        opacity: 0; 
    }
    .spinner-container{
        height:100%;
        width:100%;
        display:table;
        .spinner-outer{
            vertical-align:middle;
            height:100%;
            display:table-cell;
            .spinner{
                position: relative;
                margin: 0 auto;
                height: 100px;
                width: 100px;
                box-sizing: border-box;  
                svg {
                   animation: svg-rotate 2s linear infinite; 
                } 
            } 
        }
    }
}

@keyframes svg-rotate { 
    100% {  
        transform: rotate(360deg);
    } 
} 